

button {
    font-size: 14px;
   line-height: 14px;
   color: #fff;
   display: inline-block;
   padding: 12px 32px;
   width: auto;
   border-radius: 35px;
   text-transform: capitalize;
   background: #960014;
   border-style: none;
}

button:hover  {
  background-color: #610000;
  border-color: #610000;
}

button:active  {
  background-color: #610000;
  border-color : #610000;
}

button:focus  {
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
}

.about-button{
  padding-top: 30px;
}

/* PrivacyPolicy.css */
.privacy-policy-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 32px;
  font-family: 'Arial', sans-serif;
}

header {
  text-align: center;
  margin-bottom: 32px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.last-updated {
  font-size: 1rem;
  color: #777;
}

.policy-section {
  margin-top: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

h2 {
  font-size: 1.7rem;
  margin-top: 24px;
  color: #1a73e8;
  font-weight: bold;
}

ul {
  margin-left: 20px;
  list-style-type: disc;
}

ul ul {
  list-style-type: circle;
  margin-left: 40px;
}

p {
  margin-bottom: 16px;
}

strong {
  font-weight: bold;
}

a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}



